<template>
    <v-container fluid class="pt-0 mt-0">
        <v-row style="background-color: #005FAB;">
            <v-col cols="8" class="pt-7 px-10 pb-0">
                <h1>Inglês <br /><strong>Imersão</strong></h1>
                <v-divider class="mt-3" style="background-color: #FFD600;"/>
            </v-col>
            <v-col cols="4" class="pt-16 mt-5 px-10 pb-7">
                <notificacoes-icone style="margin-top: -50px;"/>
                <v-progress-circular style=" float:right; background-color: #005FAB; margin-top: 20px; border-radius: 50%; box-shadow: 1px 1px 1px #555;" :rotate="360" :size="82" :width="15" :value="15" color="#FFD600">
                    <strong style="font-size: 11px;">{{15}}%</strong>
                </v-progress-circular>
            </v-col>
        </v-row>
        <v-row class="justify-center mt-0">
            <v-col cols="12">
                <v-card color="#FFD600" style="border-bottom-left-radius: 25px; border-bottom-right-radius: 25px;">
                    <v-card-title style="color: #005FAB; font-size: 30px;" class="justify-center">Minha turma</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-list two-line>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Everton Arms</v-list-item-title>
                            <v-list-item-subtitle>(42) 9.9938-5656</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon><v-icon>mdi-whatsapp</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Everton Arms</v-list-item-title>
                            <v-list-item-subtitle>(42) 9.9938-5656</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon><v-icon>mdi-whatsapp</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Everton Arms</v-list-item-title>
                            <v-list-item-subtitle>(42) 9.9938-5656</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon><v-icon>mdi-whatsapp</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Everton Arms</v-list-item-title>
                            <v-list-item-subtitle>(42) 9.9938-5656</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon><v-icon>mdi-whatsapp</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Everton Arms</v-list-item-title>
                            <v-list-item-subtitle>(42) 9.9938-5656</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon><v-icon>mdi-whatsapp</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Everton Arms</v-list-item-title>
                            <v-list-item-subtitle>(42) 9.9938-5656</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon><v-icon>mdi-whatsapp</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Everton Arms</v-list-item-title>
                            <v-list-item-subtitle>(42) 9.9938-5656</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon><v-icon>mdi-whatsapp</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Everton Arms</v-list-item-title>
                            <v-list-item-subtitle>(42) 9.9938-5656</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon><v-icon>mdi-whatsapp</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider/>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import NotificacoesIcone from "@/components/NotificacoesIcone";
export default {
    name: "CursoTurma",
    components: {NotificacoesIcone},
    data: () => ({

    }),
}
</script>

<style scoped>
    h1 {text-align: left; width: 100%; padding-top:50px;  font-weight: lighter; font-size: 40px; color: #FFFFFF; line-height: 100%;}
    h1 strong {font-weight: bold;}
    h2 {font-size: 19px; font-weight: normal; text-align: center; line-height: 110%; color: #00436F;}
    h2 strong {font-weight: bold;}
    .bg {background-image: url("~@/assets/img/bg-tradutor.png"); background-repeat: no-repeat; background-position-x: -50px; background-position-y: -50px;}
</style>